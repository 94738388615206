<template>
  <div>
      <Header/>
      <div>
            <div class="circle crc1"></div>
            <div class="circle crc2"></div>
            <div class="circle crc3"></div>

            <div class="desktop">
            <div class="wrapper">
            <h1>Give feedback</h1>

                <p  class="ml-3">Care to share more about it?</p>
            </div>

            <textarea rows="10" cols="35" name="comment" form="usrform" v-model="feedback"></textarea>

            <button @click="submitFeedback">PUBLISH FEEDBACK</button>

            <div class="line bottom-line"></div>
            </div>
      </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
import db from '../firebase';
import store from '../store';
export default {
components:{Header},
mounted(){
        store.state.loading=false;
    },
data(){
    return{
        feedback:""
    }
},

methods:{
    submitFeedback(){
        console.log(this.feedback)
        //submit it on firebase
		const timestamp = new Date();
        db.collection("feedback").add({
            "name":store.getters.user.data.username,
            "feedback":this.feedback,
			"timestamp":timestamp,
        }).then(()=>{
            swal("Feedback Published");
        })
    }
}
}

</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
 @import url("https://fonts.googleapis.com/css2?family=Londrina+Solid:wght@300&display=swap");
 body {
	 box-sizing: border-box;
	 width: 100%;
	 height: 100%;
	 position: relative;
	 background: #a1e6b8;
}
 .desktop {
	 position: relative;
	 background: #fffefc;
	 display: block;
	 margin: 50px auto;
	 width: 100%;
	 height: 100%;
}
 .circle {
	 position: absolute;
	 width: 200px;
	 height: 200px;
	 background: #FA5F5F;
	 border-radius: 34% 66% 70% 30% / 32% 37% 63% 68%;
     z-index:-1;
}
 .crc1 {
	 margin-left: 20%;
}
 .crc2 {
	 width: 150px;
	 height: 150px;
	 margin-left: -100px;
}
 .crc3 {
	 width: 100px;
	 height: 100px;
	 margin-top: -100px;
	 margin-left: 10%;
}
 .icons {
	 position: relative;
	 float: right;
	 padding: 8px 2px;
	 width: 15px;
	 height: auto;
}
 .battery {
	 width: 20px;
	 height: auto;
	 padding-right: 20px;
	 margin-top: -1px;
}
 .time {
	 position: absolute;
	 font-family: "Roboto", sans-serif;
	 font-size: 12px;
	 padding: 10px 18px;
	 font-weight: bold;
}
 .arrow-left {
	 position: relative;
	 padding: 40px 15px 0px 15px;
}
 .wrapper {
	 padding-left: 18px;
	 padding-right: 18px;
}
 h1 {
	 font-family: "Londrina Solid", cursive;
	 letter-spacing: 1px;
	 color: #3c3b39;
}
 p {
	 font-family: "Roboto", sans-serif;
	 color: #61605e;
	 padding-top: 20px;
	 font-size: 13px;
	 font-weight: bold;
	 letter-spacing: 0.3px;
}
 .stars {
	 display: inline-block;
	 font-size: 40px;
	 font-family: Times;
	 line-height: 1;
	 padding-left: -5px;
	 margin-top: -8px;
}
 .stars::before {
	 content: "★★★★★";
	 letter-spacing: 3px;
	 background: linear-gradient(90deg, #fdd043 50%, #e0deda 50%);
	 -webkit-background-clip: text;
	 -webkit-text-fill-color: transparent;
}
 .line {
	 padding-top: 25px;
	 border-bottom: 0.8px solid #e0deda;
}
 textarea {
	 width: 88%;
	 display: block;
	 margin: 0 auto;
	 border: 1px solid #e0deda;
	 border-radius: 5px;
	 font-family: "Roboto", sans-serif;
	 color: #61605e;
	 padding: 8px;
	 font-size: 13px;
	 font-weight: bold;
	 letter-spacing: 1px;
	 outline: none;
}
 button {
	 background-color: #FA5F5F;
	 border: none;
	 outline: none;
	 padding: 15px;
	 width: 90%;
	 border-radius: 5px;
	 display: block;
	 margin: 33px auto 10px auto;
	 color: white;
	 font-weight: 800;
	 font-size: 12px;
}
 .bottom-line {
	 border-bottom: 5px solid #cfcecb;
	 width: 30%;
	 display: block;
	 margin: 0 auto;
	 border-radius: 8%;
}

</style>